* {
  font-display: swap;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
}

.slick-slider {
  .slick-track {
    width: 30000px !important;
  }

  .slick-slide {
    transition: opacity 0.3s;
    opacity: 1;
  }

  // .slick-slide:not(.slick-current) {
  //   opacity: .3;
  // }
}

.empty-progress-bar {
  height: 18px;

  @media screen and (min-width: 768px) {
    height: 27px;
  }
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.pulse-button {
  animation: shadow-pulse 1s infinite;
}


/* PDF Viewer */
.pdf-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.6);
  padding: 6.25rem 0 1.875rem;
  flex-direction: column;

  .pdf-main {
    display: flex;
    max-height: calc(100% - 1.875rem);
    position: relative;
  }

  .pdf-close {
    position: absolute;
    cursor: pointer;
    top: -1.75rem;
    right: 0;
    width: 1.25rem;
    height: 1.25rem;
    fill: #fff;

    @media screen and (max-width: 767px) {
      right: calc(50% - 0.625rem);
    }
  }
}

.pdf-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding: 1.25rem;
  margin: 0 1.25rem;
  box-shadow: 0 0 10px 1px rgba(0,0,0,.7);

  @media screen and (max-width: 767px) {
    padding: 0;
    margin: 0 0.5rem;
  }

  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .react-pdf__Page {
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  .react-pdf__Page > canvas {
    max-width: 100%;
    height: auto !important;
  }
}

/* Terms Modal */
.terms-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.6);
  padding: 6.25rem 0 1.875rem;
  flex-direction: column;
  z-index: 1024;

  .terms-main {
    display: flex;
    max-height: calc(100% - 1.875rem);
    position: relative;

    @media screen and (min-width: 768px) {
      max-width: 836px;
      width: 100%;
    }
  }

  .terms-close {
    position: absolute;
    cursor: pointer;
    top: -1.75rem;
    right: 0;
    width: 1.25rem;
    height: 1.25rem;
    fill: #fff;

    @media screen and (max-width: 767px) {
      right: calc(50% - 0.625rem);
    }
  }
}

.terms-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding: 1.25rem;
  margin: 0 1.25rem;
  box-shadow: 0 0 10px 1px rgba(0,0,0,.7);
  text-align: justify;

  p {
    margin-bottom: 1rem;
  }

  ol {
    padding-left: 1.5rem;

    li {
      margin-bottom: 1rem;
    }
  }

  a {
    color: #007bff;
  }

  @media screen and (max-width: 767px) {
    margin: 0 0.5rem;
  }
}

.link-text {
  color: #529bd7;
  text-decoration: underline;
}