.solar-map {
  flex: 0 0 33.333333%;
  width: 33.333333%;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    .logo-googlemap {
      @media screen and (min-width: 768px) {
        height: 1.375rem;
      }
    }
  }

  &-main-v2 {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 260px;

    @media screen and (max-width: 768px) {
      height: 350px;
    }

    @media screen and (max-width: 550px) {
      height: 250px;
    }

    svg {
      stroke: transparent;
      stroke-width: 0px;
      width: 100%;
      height: 100%;

      image {
        stroke: transparent;
        stroke-width: 0px;
      }
    }
  }

  &-marker {
    position: absolute;
    width: 40px;
    height: 40px;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
  }

  &-overlay {
    height: 100%;
    width: 100%;
    background: #000000;
    position: absolute;
    opacity: 0.2;
  }
}
